.orgMemberAddWrapper {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  height: 30px;

  button {
    height: 30px;
  }
}

// members, lectures
.orgMemberTable,
.orgLectureTable {
  border-radius: 12px;
  overflow: hidden;

  tr {
    user-select: none;
  }

  thead,
  tbody {
    display: block;

    tr {
      display: flex;
      width: 100%;
      min-width: 1080px;
      max-width: 1280px;
      justify-content: space-between;
      border-bottom: 1px solid #a8a8a8;

      th,
      td {
        border: none;
      }

      td {
        //border-top: 1px solid #a8a8a8;
      }
    }
  }

  thead {
    tr {
      background-color: #f5f5f5;
    }
  }

  tbody {
    height: 100%;
    min-height: 300px;
    max-height: 480px;
    overflow-y: auto;

    tr:last-child {
      border-bottom: none;
    }
  }
}

// members
.checkbox,
.id,
.role,
.name,
.phone,
.email {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  width: 40px;
}

.id {
  width: 40px;
}

.role {
  width: 100px;
}

.name {
  width: 100px;

  > div {
    width: 100%;

    > div {
      width: 100%;
      padding: 0 1rem;

      > input {
        width: 100%;
      }
    }
  }
}

.phone {
  width: 160px;
}

.email {
  width: 320px;
}

.addItem {
  width: 100%;
  cursor: pointer;
}

// lectures
.lectureId,
.title,
.schedule,
.deleteItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lectureId {
  width: 40px;
}

.title {
  width: 400px;

  > div {
    width: 100%;

    > div {
      width: 100%;
      padding: 0 1rem;

      > input {
        width: 100%;
      }
    }
  }
}

.schedule {
  width: 280px;
}

.deleteItem {
  width: 80px;

  button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #a8a8a8;
  }
}

.deleteButton:hover {
  color: #ff0000;
  background: #ffdddd !important;
}

.addButton:hover {
  color: #0000ff;
  background: #ddddff !important;
}

.willAdd {
  height: 60px;
  background-color: #f4f4ff;
  color: #8888ff;

  button {
  }
}

.willDelete {
  background-color: #fff5f5;

  button {
    color: #ff0000;
  }
}

.addActionRow {
  height: 60px;
}
