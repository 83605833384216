.table--wrap {
  width: 100%;
  height: 100%;
}

table {
  margin-bottom: 12px;
  width: 100%;
  border-top: 1px solid #444;
  border-collapse: collapse;

  th,
  td {
    padding: 12px;
    border-bottom: 1px solid #444;
    border-left: 1px solid #444;
    text-align: center;

    a {
      color: #999;
    }

    &:first-child {
      border-left: none;
    }
  }

  th > div{
    &:hover {
      span {
        opacity: 1;
      }
    }
  }

  .table-header-top__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    svg {
      margin-left: 0.2rem;
    }
  }

  .click-available-header {
    cursor: pointer;
  }

  tbody tr {
    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }

  .dragged-row {
    background-color: #f5f5f5;
    opacity: 0.5;
    border-top: 1px solid #444;
    display: flex;

    td {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  tr.deleted td {
    color: #ccc;
    text-decoration: line-through;
  }

  &.dragging__table tbody tr:hover {
    background-color: #eee;
    cursor: grab;
  }

  &.paused-dragging__table {
    tr > td:not(:last-child) {
      pointer-events: none;
    }

    tbody {
      user-select: none;
    }
  }
}


.pagination {
  display: flex;
  justify-content: center;

  span {
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;

    &.selected {
      font-weight: bold;
      pointer-events: none;
    }

    &:hover {
      background-color: #eee;
    }
  }

  button {
    padding: 6px 12px;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }
}
