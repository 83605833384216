.posts-list {
  section:first-child {display: flex;
    align-items: center;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
      margin-bottom: 6px;
    }

  }

  .posts-list__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 10px;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .posts-grouping-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: #999 solid 1px;
    border-top: #999 solid 1px;
    margin-bottom: 14px;

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      cursor: pointer;
      border-radius: 3px;
    }

    .posts-grouping-title-container {
      display: flex;
      align-items: center;
      border-bottom: #999 solid 1px;
      padding: 7px 0 7px 0;
      b {
        margin-right: 10px;
      }
    }

    .posts-grouping-select-container {
      display: flex;
      align-items: center;
      padding: 7px 0 7px 0;

      select {
          width: 100px;
          height: 24px;
          margin: 0 10px 0 7px;
          box-sizing: border-box;
      }
    }
  }

  .posts-board-section {
    display: flex;
    align-items: center;
    margin: 6px 0 6px 0;
    padding-bottom: 10px;
    border-bottom: black solid 1px;

    select {
      width: 100px;
      height: 24px;
      margin-left: 7px;
      box-sizing: border-box;
    }
  }

  .posts-list__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 3px;
      height: 24px;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .posts-list-top-container {
    display: flex;
    justify-content: space-between;
  }

  .posts-list__total {
    margin-bottom: 6px;
  }

  table {
    tr {
      height: 60px;
    }
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }
  .btn-download {
    color: #75b96d;
  }
}