.field {
  display: flex;
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .field__title {
    margin-right: 12px;
    width: 150px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .field__tooltip {
    margin-left: 3px;
    line-height: 30px;
  }

  .field__body {
    flex: 1;
    display: flex;
    align-items: center;

    .field__title {
      font-weight: normal;
    }

    select {
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      border: 1px solid #999;
      border-radius: 4px;
      font-size: 14px;
      color: #333;

      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 0.5rem center;
      background-size: 1em;
    }
  }

  .btn {
    height: 30px;
    width: 90px;
    border-radius: 6px;
  }

  span {
    margin-left: 3px;
  }

  input {
    padding: 0 6px;
    height: 30px;
    box-sizing: border-box;
  }

  input[type='file'] {
    height: auto;
  }

  textarea {
    padding: 6px;
    width: 100%;
    height: 120px;
    border: 1px solid #999;
    border-radius: 6px;
    resize: none;
    outline: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  button {
    margin-right: 6px;
  }
}

.field--input {
  input {
    width: 300px;
    box-sizing: border-box;
    border: 1px solid #999;
    border-radius: 6px;
  }
}

.field--image {
  .field__body {
    align-items: flex-start;
  }

  img {
    border: 1px solid #999;
    border-radius: 6px;
    margin-right: 6px;
  }

  .btn {
    width: auto;
    padding: 0 30px;
  }
}

.field--file,
.field--video {
  a {
    font-size: 14px;
    color: #444;
    margin-right: 6px;
  }
}

.field--date,
.field--period {
  .react-datepicker-wrapper {
    width: auto;
  }

  input {
    width: 90px;
    text-align: center;
    border: none;
    border-bottom: 1px solid #999;
    border-radius: 0;
    cursor: pointer;
  }

  span {
    margin: 0 10px 0 10px;
  }

  .field--input {
    margin: 0 0 0 5px !important;

    input {
      width: 120px;
    }
  }
}

.field--tutor {
  .field__tutors {
    margin-left: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > div {
      margin-right: 6px;
      display: flex;
      align-items: center;
    }

    .chip {
      height: 24px;
      line-height: 24px;
      z-index: 2;

      &:hover {
        background-color: #ea7280;
        opacity: 1;
      }

      + .field {
        margin: 0;

        input {
          width: 80px;
          text-align: right;
          font-size: 12px;
          height: 30px;
          margin-left: -16px;
          z-index: 1;
        }

        + span {
          margin-right: 8px;
          font-size: 12px;
        }
      }
    }
  }
}

.field--categories {
  .field__title {
    line-height: unset;
  }

  .field__category-group {
    display: flex;
    margin-bottom: 6px;
  }

  .field__category-group__title {
    margin-right: 6px;
    width: 90px;
  }
}

.field--online-option {
  .field:nth-of-type(1) {
    input {
      width: 120px;
    }
  }
}

.field--round-online-options,
.field--offline-options {
  max-height: 420px;

  .field__body {
    overflow: auto;
  }

  .field:nth-of-type(1) {
    input {
      width: 120px;
    }
  }

  .field--input {
    margin: 0;
  }

  .field__option-container {
    height: 100%;
  }

  td {
    font-size: 0.875rem;
  }

  .PrivateSwitchBase-input {
    width: 100%;
    height: 100%;
  }

  .field__option-table {
    width: 100%;
    height: 100%;
    border: none;

    .offline--options--input--location {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    thead {
      position: sticky;
      top: 0;
      background-color: #eee;
      height: 3rem;
      font-size: 0.875rem;
      z-index: 1;
    }

    tr {
      border: none;
      border-bottom: 1px solid rgba(153, 153, 153, 0.6);
      white-space: nowrap;
    }

    td {
      cursor: default;
      padding: 5px 12px;
    }

    th,
    td {
      border: none;
      font-weight: normal;
    }

    tr:nth-last-child(1) {
      border-bottom: none;
    }

    th {
      padding: 0 12px;
    }

    .close-button {
      width: 15px;
      padding-left: 0px;
      cursor: pointer;
    }

    #class_location__th {
      min-width: 210px;
    }

    #demo-select-small {
      padding: 3.5px 16px 3.5px 7px;
      position: relative;
      border: 1px solid rgba(68, 68, 68, 0.3);
    }

    .MuiInputLabel-root {
      line-height: 0.9rem;
      font-size: 14px;
    }
  }
}

.field--chapters,
.field--sample-videos {
  display: block;
  width: 100%;

  .field__title {
    margin-bottom: 6px;
  }

  input {
    width: 100% !important;
  }
}

.field--chapters {
  td {
    font-size: 14px;
  }

  .field__chapter {
    text-align: left;
    background-color: #eee;

    input {
      flex: 1;
      margin-left: 6px;
      background-color: transparent;
      border: none;
      outline: none;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .clickedTh,
  .clickedTr {
    background-color: rgba(141, 146, 238, 0.8);
  }

  .field__survey {
    background-color: rgba(103, 124, 230, 0.3);
  }
  .survey {
    background-color: rgba(103, 124, 230, 0.08);
  }
}

.field--rich-text {
  display: block;

  .field__body {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
  }
}

.ql-html-popupContainer {
  background: #ffffff !important;

  .ql-html-popupTitle {
    margin-bottom: 5px;
    font-style: unset;
    color: #cb0101;
  }

  .ql-html-textArea {
    background-color: #26292f;
  }

  .ql-html-buttonGroup {
    margin-bottom: 5px;

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      border: none;
    }
    .ql-html-buttonOk {
      background-color: #69a0e3;
      box-shadow: 1px 1px 2px #69a0e3;
    }

    .ql-html-buttonCancel {
      background-color: #6e7d96;
      box-shadow: 1px 1px 2px #6e7d96;
    }

    button:hover {
      background-color: #98b4da;
      box-shadow: 1px 1px 2px #98b4da;
      cursor: pointer;
    }
  }

  .ql-syntax {
    color: #ffffff;
  }

  .ql-editor::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
    //background-color: rgb(255, 0, 0);
  }
  .ql-editor::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ffa07a;
  }
}

.field--lectures {
  display: block;

  .field__body {
    display: block;
    margin-top: 12px;
  }

  .field__lectures {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #444;

    .field__lectures--title {
      font-size: 16px;
      margin-bottom: 6px;
    }

    table {
      margin-bottom: 12px;
      width: 100%;
      border-top: 1px solid #444;
      border-collapse: collapse;

      th,
      td {
        padding: 5px 12px;
        border-bottom: 1px solid #444;
        border-left: 1px solid #444;
        text-align: center;

        &:first-child {
          border-left: none;
        }
      }

      .clickedTr {
        background-color: #ee8d95cc;
      }

      .close-button {
        width: 15px;
        padding-left: 0px;
        cursor: pointer;
      }
    }
  }

  .field__search--title {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .field__search {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    select {
      background-image: unset;
      appearance: auto;
    }

    input {
      margin: 0 6px;
      width: 240px !important;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }

    span {
      font-size: 12px;
    }
  }

  td {
    padding: 0 12px;
  }

  .field__review-transfer-logs {
    margin-bottom: 12px;
    font-size: 13px;

    .field__transfer-title {
      color: #ff041b;
      font-weight: bold;
      margin-bottom: 2px;
    }

    span {
      color: #3d3d3d;
    }
  }

  .field--lectures--selected {
    padding: 6px 12px;
  }
}

.field--survey-sections {
  display: block;

  .field__title {
    margin-bottom: 0.5rem;
  }

  .questions--container {
    margin-top: 1rem;
    padding-top: 0.5rem;
    border-top: 1px solid #999999;

    button {
      margin: 0.5rem 0;
    }
  }

  .questions-top--container {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;

    > * {
      margin-right: 0.5rem;
    }
  }

  .questions-top__infos {
    > * {
      margin-right: 0.5rem;
    }
  }

  .questions-top-close__button {
    display: flex;
    justify-content: flex-end;
  }

  .questions-middle--container {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
  }

  .questions__total {
    background-color: #ffffff;
    margin: unset;
    margin-bottom: 6px;
    padding: 0 0 6px 0;
    font-size: 0.8rem;
  }

  .questions__info {
    white-space: nowrap;
    color: #8d8d8d;
    font-weight: bold;
  }

  .close-button {
    width: 1rem;
    padding-left: 0;
    cursor: pointer;
  }

  .field__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div {
      width: 100%;
    }

    table {
      td:nth-child(1) {
        width: 1%;
      }
      td:nth-child(2) {
        width: 7%;
      }
      td:nth-child(3) {
        width: 7%;
      }
      td:nth-child(4) {
        width: 10%;
      }
      td:nth-child(5) {
        width: 10%;
      }
      td:nth-child(6) {
        width: 60%;
      }
      td:nth-child(7) {
        width: 7%;
      }
    }
  }
}

.field--choice {
  .close-button {
    width: 1rem;
    padding-left: 0;
    cursor: pointer;
  }

  .questions-choice-content__input {
    width: 100%;
    border: none;
    outline: none;
  }

  .dragged-row-choice {
    background-color: #f5f5f5;
    opacity: 0.5;
    border-top: 1px solid #444;
  }

  .disabled-row {
    pointer-events: none;
  }

  .field__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div {
      width: 100%;
    }

    table {
      tr {
        height: 60px;
        width: 100%;
        background-color: transparent;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #eee;
        }
      }

      td:nth-child(1) {
        width: 5%;
      }
      td:nth-child(2) {
        width: 60%;
      }
      td:nth-child(3) {
        width: 5%;
      }
      td:nth-child(4) {
        width: 5%;
      }
    }
  }
}

.field__lecture-terms {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.field--registrationFields {
  border-top: 1px solid #444;
  padding-top: 1rem;
  margin-top: 1rem;
}

.field__select-registration-fields__modal {
  .modal__buttons {
    display: flex;
    justify-content: flex-end;
    bottom: 20px;
    right: 20px;
    gap: 10px;
  }
}
