.user-list {
  .user-list__filter {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }
  }

  .user-list__filter__date {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 12px;

    input {
      margin: 0 3px;
      width: 90px;
    }
  }

  .user-list__filter__select {
    display: flex;
    align-items: center;

    input {
      margin: 0 3px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .user-list__total {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    button {
      margin-left: auto;
    }
  }

  td:nth-of-type(10),
  td:nth-of-type(11) {
    color: black;
    text-decoration: none;
  }
}
