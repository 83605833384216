.btn {
  width: 120px;
  height: 36px;
  color: white;
  background-color: black;
  box-shadow: 1px 1px 2px #ccc;
  border: none;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.btn--positive {
  background-color: #dc5464;
}

.btn--negative {
  background-color: #999;
}

.btn--excel {
  background-color: #5dbb63;
}
