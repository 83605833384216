.video-list {
  section {
    display: flex;
    margin-bottom: 12px;
    align-items: flex-end;
  }

  .video-list__search {
    display: flex;

    input {
      margin-right: 3px;
      width: 210px;
      height: 24px;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .video-list__buttons {
    margin-left: auto;

    button {
      margin-left: 6px;
    }
  }

  .video-list__category {
  }

  .video-list__category__types {
    display: flex;
    padding: 6px;
    border-top: 1px solid #999;
  }

  .video-list__category__type {
    margin-right: 12px;
    font-weight: bold;
    color: #999;
    cursor: pointer;
  }

  .video-list__category__type--selected {
    color: black;
    pointer-events: none;
  }

  .video-list__category__categories {
    padding: 6px;
    border-top: 1px solid #999;
  }

  .video-list__category__selected-categories {
    padding: 6px;
    border-top: 1px solid #999;
  }

  .video-list__category__clear {
    display: inline-flex;
    font-size: 12px;
    cursor: pointer;
  }
}
