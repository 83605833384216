.select-search {
  position: relative;
  box-sizing: border-box;
}

.select-search__select {
  background: #fff;
  border: 1px solid #444;
}

.select-search__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option,
.select-search__not-found {
  display: block;
  width: 100%;
  padding: 6px 12px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search__option.is-selected {
  background: #eee;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #eee;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #eee;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 36px;
  right: 0;
  left: 0;
  border-radius: 6px;
  overflow: auto;
  max-height: 240px;
}
