.surveys {
  section:first-child {
    display: flex;
    align-items: center;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
    }
  }

  .surveys__date {
    white-space: nowrap;
    margin-right: 10px;

    .surveys-detail__date {
      display: flex;
      align-items: center;
      padding: 0.2rem 0 0.2rem 0;
    }

    input {
      width: 8.5rem;
      padding: 0 6px;
      height: 1.7rem;
      font-size: 0.9rem;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      margin-left: 0.4rem;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .surveys__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 3px;
      height: 24px;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .btn-detail,
  .btn-statistics {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn-statistics {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .surveys-container {
    .surveys__total {
      background-color: #ffffff;
      margin: unset;
      padding: 1rem 0 1rem 0;
    }

    tbody tr {
      background-color: transparent;
      transition: background-color 0.3s ease;
    }

    table {
      tr {
        height: 60px;
        width: 100%;
        &:hover {
          background-color: #eee;
        }
      }

      td:nth-child(1) {
        width: 3%;
      }
      td:nth-child(2) {
        width: 10%;
      }
      td:nth-child(3) {
        width: 10%;
      }
      td:nth-child(4) {
        width: 10%;
      }
      td:nth-child(5) {
        width: 3%;
      }
      td:nth-child(6) {
        width: 5%;
      }
      td:nth-child(7) {
        width: 9%;
      }
      td:nth-child(8) {
        width: 2%;
      }
    }
  }
}
