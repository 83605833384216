.sales {
  .sales__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .btn--excel {
    float: right;
    margin-bottom: 12px;
  }
}
