.container {
  .content-table {
    font-size: 0.875rem;

    table {
      td {
        height: 2rem;
        padding: 0.5rem;

        div {
          max-height: 2rem;
          line-height: 1rem;
          display: -webkit-box;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(11) {
          max-width: 80px;
        }

        &:nth-child(3) {
          max-width: 500px;
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          max-width: 120px;
        }

        &:nth-child(10) {
          max-width: 80px;
        }
      }
    }
  }
}
