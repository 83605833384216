.add-student-response-alert {
  z-index: 9999 !important;

  p {
    text-wrap: none;
    line-height: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #1d5d90;
    }
  }

  svg {
    margin-bottom: -1px;
  }
}
