.table-top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

  .table-top-first-container {
    display: flex;
    align-items: center;
  }

  .waiting-users-message__date {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 10px;
    z-index: 9999;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
      z-index: 9999;
    }
  }

  .waitings__search{
    display: flex;
    align-items: center;

    input {
      margin-left: 5px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
      margin-left: 5px;
    }
  }

  .waitings-switch {
    display: flex;
    align-items: center;
  }
}
