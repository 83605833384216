.single {
  max-width: 1440px;
  @media screen and (max-width: 1090px) {
    max-width: 1080px;
  }
  width: 100%;

  .field__title {
    width: 180px;
  }

  .field--rich-text {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #999;
  }

  .field:nth-of-type(8) {
    .field:nth-of-type(1),
    .field:nth-of-type(4) {
      input {
        width: 120px;
      }
    }
  }

  .field:nth-of-type(10) {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #999;
  }

  .field--curriculum {
    display: block;

    & > .field__body,
    & > .field__body > .field--chapters > .field__body {
      display: block;
      margin-top: 6px;
    }

    table {
      th {
        white-space: nowrap;
      }
      td {
        font-size: 0.75rem;
        color: red;
      }

      input {
        width: 100%;
        border: none;
        background-color: transparent;
      }

      input[type='checkbox'] {
        width: auto;
      }
    }
  }

  .single__buttons {
    display: flex;
    margin-top: 12px;
    justify-content: flex-end;

    .btn {
      margin-left: 6px;
    }
  }
}

.tutor-rate-wrap {
  input {
    width: 60px;
    text-align: right;
  }
}
