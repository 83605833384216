.given {
  max-width: 900px;

  .field__title {
    width: auto;
  }

  .field--lectures {
    display: block;

    .field__body {
      display: block;
      margin-top: 6px;
      padding-left: 6px;
    }

    .field--lectures__search {
      display: flex;
      align-items: flex-start;

      input,
      select {
        height: 24px;
        box-sizing: border-box;
      }

      input {
        margin: 0 3px;
      }

      button {
        width: 60px;
        height: 24px;
        font-size: 12px;
        border-radius: 3px;
        box-shadow: none;
      }
    }

    table {
      margin-top: 6px;

      td {
        padding: 0 6px;
      }
    }

    .field--lectures__selected {
      text-decoration: underline;
    }
  }

  .field--input {
    input {
      width: 90px;
    }
  }

  .field--textarea {
    display: block;
  }

  .field--user-ids {
    .chip {
      margin-bottom: 3px;
    }

    .btn {
      vertical-align: middle;
      padding: 3px 18px;
      width: auto;
      height: 21px;
      font-size: 12px;
      border-radius: 12px;
    }
  }

  .classAdd__buttons {
    display: flex;
    margin-top: 12px;
    justify-content: flex-end;

    .btn {
      margin-left: 6px;
    }
  }
}
