.add-questions--container {
  .add-questions__table__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .add-questions__table__header__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 3px;
      height: 24px;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  table {
    tr {
      height: 60px;
      width: 100%;
      background-color: transparent;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #eee;
      }
    }

    td:nth-child(1) {
      width: 3%;
    }
    td:nth-child(2) {
      width: 7%;
    }
    td:nth-child(3) {
      width: 10%;
    }
    td:nth-child(4) {
      width: 10%;
    }
    td:nth-child(5) {
      width: 62%;
    }
    td:nth-child(6) {
      width: 8%;
    }
  }
}
