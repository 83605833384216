.admin {
  max-width: 840px;

  .admin__search {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 3px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .admin__list {
    margin-bottom: 18px;
  }

  .admin__auth {
    .admin__auth__id {
      margin-bottom: 12px;
    }

    table {
      margin-bottom: 12px;
      width: auto;
    }

    th,
    td {
      white-space: nowrap;
    }

    button {
      display: block;
      margin-left: auto;
    }
  }
}
