.wrapper {
  padding: 16px;
}

.organizationListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
