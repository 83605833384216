.review-best-list {
  section {
    display: flex;
    align-items: flex-start;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
    }
  }

  .review-best-list__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 6px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .single-list__total {
    margin-bottom: 6px;
  }

  .btn-detail-view {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }
}
