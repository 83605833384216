.banner-list {
  max-width: 780px;

  .banner-list__buttons {
    margin-bottom: 18px;
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 6px;
    }
  }
}
