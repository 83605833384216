@import url("https://webfontworld.github.io/amore/AritaBuri.css");
@import url("https://webfontworld.github.io/amore/AritaDotum.css");

.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comicSans,
.ql-font span[data-value="comicSans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courierNew,
.ql-font span[data-value="courierNew"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
.ql-font-aritaBuri,
.ql-font span[data-value="aritaBuri"]::before {
  font-family: "AritaBuri";
}
.ql-font-aritaDotum,
.ql-font span[data-value="aritaDotum"]::before {
  font-family: "AritaDotum";
}

.quill {
  width: 100%;
}

.ql-toolbar {
  width: 100%;

  .lineHeightSvg {
    position: absolute;
    margin-top: 3px;
    margin-left: 1rem;
    width: 18px;
    height: 18px;
  }

  .ql-lineHeight {
    width: 65px;
    position: relative;

    .ql-picker-item:before {
      content: attr(data-label);
    }
  }
}

.ql-editor {
  min-height: 420px;
  max-height: 900px;
  overflow: scroll;
  padding-right: 277px;

  .ql-video {
    width: 100%;
    max-width: 540px;
    aspect-ratio: 16/9;
  }

  p {
    line-height: 1.69;
  }
}
