.video {
  max-width: 720px;

  .video__fields {
    .field {
      &:nth-child(7) {
        input {
          width: 90px;
        }
      }
    }
  }

  .video__btns {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;

    * {
      margin-left: 6px;
    }

    .btn:nth-of-type(2) {
      width: 180px;
    }
  }

  .field--subtitles {
    & > .field__body {
      display: block;

      .field__title {
        width: 90px;
        height: auto;
      }
    }
  }

  .field--file {
    .field__title {
      width: 210px;
    }
  }
}
