.single-list {
  section {
    display: flex;
    align-items: flex-start;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
      margin-bottom: 6px;
    }
  }

  .single-list__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 6px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .single-list-top-container {
    display: flex;
    justify-content: space-between;
  }

  .single-list__total {
    margin-bottom: 6px;
  }

  .single-list__options {
    display: flex;
    margin-bottom: 6px;

    > div {
      display: flex;
      align-items: center;
      margin-left: 12px;

      input {
        box-sizing: border-box;
        margin: 0 3px;
        width: 120px;
      }
    }

    .single-user-list__selling {
      > span {
        padding: 5px;
      }
    }

    .lecture-user-list__date {
      > div {
        margin: 0 3px;
      }
    }
  }

  .user-class-count {
    text-decoration: underline #777;
  }

  .waiting-count {
    text-decoration: underline #777;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn-download {
    color: #75b96d;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn-download {
    color: #75b96d;
  }
}

.swal2-title {
  font-size: 1.25rem !important;
  text-align: left !important;
  font-weight: 800 !important;
  padding: 0.8em 3em 0 1em !important;
}

.swal2-html-container {
  text-align: left !important;
  font-size: 1rem !important;

  ul,
  ol {
    margin-block-start: 0.25rem !important;
    padding-inline-start: 1rem !important;
  }

  ol {
    li {
      font-weight: 600 !important;

      ul {
        li {
          font-weight: normal !important;
        }
      }

      + li {
        margin-top: 1rem !important;

        ul {
          li {
            font-size: 0.875rem !important;

            + li {
              margin-top: 0.25rem !important;
            }
          }
        }
      }
    }
  }
}

.single-list {
  table {
    tr {
      height: 60px;
    }
  }
}
