.package-list {
  section {
    display: flex;
    align-items: flex-start;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
      margin-bottom: 6px;
    }
  }

  .package-list__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 6px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .package-list-top-container {
    display: flex;
    justify-content: space-between;
  }

  .package-list__total {
    margin-bottom: 6px;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .package-list__options {
    display: flex;
    margin-bottom: 6px;

    > div {
      display: flex;
      align-items: center;
      margin-left: 12px;

      input {
        box-sizing: border-box;
        margin: 0 3px;
        width: 120px;
      }
    }

    .single-user-list__selling {
      > span {
        padding: 5px;
      }
    }

    .lecture-user-list__date {
      > div {
        margin: 0 3px;
      }
    }
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .lecture-user-list__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 6px;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn-download {
    color: #75b96d;
  }

  .lecture-user-list__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 6px;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn-download {
    color: #75b96d;
  }
}
