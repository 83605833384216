.tutor {
  max-width: 720px;

  .tutor__fields {
    .field {
      input:not([type="radio"]) {
        width: 420px;
      }
    }
  }

  .tutor__buttons {
    display: flex;
    margin-top: 12px;
    justify-content: flex-end;
  }

  .btn {
    margin-left: 6px;
  }

  .uncheck {
    background-color: rgba(220, 84, 100, 0.15)
  }
}
