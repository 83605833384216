.post-list {
  section:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
    }
  }

  .post-list__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 10px;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .post-list__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 3px;
      height: 24px;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .gnb-name-container {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0;
    border-top: 1px solid #999;

    input {
      margin: 0 3px;
      height: 24px;
      width: 25%;
      box-sizing: border-box;
    }

    button {
      display: flex;
      align-items: center;
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
      background-color: #1976d2;
      border: #1976d2;
      color: #ffffff;

      &:hover {
        background-color: #8dabe1;
        border: #8dabe1;
        cursor: pointer;
      }

      span {
        margin-right: 5px;
      }

      &:disabled {
        background-color: #a8a8a8;

        &:hover {
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }

  .post-list-top-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 15px 0;
    border-top: 1px solid #999;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .top-event-list__total,
  .normal-event-list__total {
    background-color: #ffffff;
    margin: unset;
    padding: 0 0 6px 0;
  }

  .top-event-list-top-container {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
  }

  .top-event-list-container {
    margin-bottom: 3rem;
  }

  .top-event-list__info {
    color: #8d8d8d;
    font-weight: bold;
  }

  .not-dragging tbody tr {
    background-color: transparent;
    transition: background-color 0.5s ease;
  }

  table {
    tr {
      height: 60px;
      width: 100%;

      &:hover {
        background-color: #eee;
      }
    }

    td:nth-child(1) {
      width: 3%;
    }

    td:nth-child(2) {
      width: 5%;
    }

    td:nth-child(3) {
      width: 25%;
    }

    td:nth-child(4) {
      width: 5%;
    }

    td:nth-child(5) {
      width: 8%;
    }

    td:nth-child(6) {
      width: 10%;
    }

    td:nth-child(7) {
      width: 9%;
    }

    td:nth-child(8) {
      width: 5%;
    }

    td:nth-child(9) {
      width: 10%;
    }

    td:nth-child(10) {
      width: 5%;
    }
  }

  .events-blue tbody tr {
    background-color: #f3f5ff;
    transition: background-color 0.5s ease;
  }

  .events-red tbody tr {
    background-color: #ffe7e7;
    transition: background-color 0.5s ease;
  }

  .dragged-row {
    background-color: #f5f5f5 !important;
    opacity: 0.5 !important;
  }
}
