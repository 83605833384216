.tutors {
  width: 100%;
  max-width: 960px;
  font-size: 0.875rem;

  .making-btn {
    all: unset;
    margin-left: 6px;
  }

  .tutors__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  td:last-child {
    max-width: 50px;
    width: 50px;
  }

  td:nth-child(6)::-webkit-scrollbar {
    display: none;
  }
}
