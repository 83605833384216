.lecture-user-list {
  .MuiList-root {
    font-size: 0.875rem;
  }

  .MuiSelect-select {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    height: 1.5rem;
  }

  .lecture-user-list__top {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
  }

  .lecture-user-list__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;

    .MuiFormControl-root {
      margin: 0 0.5rem;

      input {
        width: 5.75rem;
        height: 1.5rem;
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
      }
    }
  }

  .lecture-user-list__search {
    display: inline-flex;
    align-items: center;
    margin-left: 1.5rem;
    column-gap: 0.5rem;

    input {
      width: 12rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }
  }

  .lecture-user-list__filter {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }

  .lecture-user-list__total {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      margin-left: auto;
    }
  }

  .lecture-user-list__excel--btn {
    width: 120px;
    height: 36px;
    color: white;
    box-shadow: 1px 1px 2px #ccc;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    background-color: #5dbb63;

    &:hover {
      opacity: 0.6;
    }

    &.lecture-user-list__excel__disabled--btn {
      opacity: 0.3;
    }
  }
}
