.user-detail {
  max-width: 780px;

  .user-detail__fields {
    padding: 18px;
  }

  .user-detail__buttons {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 6px;
    }
  }
}
