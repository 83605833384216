.coupon--send--wrap {
  width: 100%;
  height: 100%;

  .MuiList-root {
    font-size: 0.875rem;
  }

  .MuiSelect-select {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .coupon--send--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .coupon--send--search--wrap {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }

  .coupon--send--main {
    .coupon--send--total {
      margin-bottom: 0.5rem;
    }

    .coupon--table--wrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      .MuiTableContainer-root {
        width: 99.5%;
        overflow: auto;
        border-radius: 0.5rem;
        box-shadow: 0 0 0 1px #DDDDDDFF;

        table {
          border: unset;
          margin: 0;

          th {
            background-color: rgb(246, 246, 246);
          }

          td, th {
            border-bottom: 1px solid #DDDDDDFF;
            border-left: 1px solid #EEEEEE;

            &:first-child {
              border-left: none;
            }
          }

          tr {
            transition: background-color 0.25s ease;

            &.imminent {
              background-color: #FFF4F4FF;

              &:hover {
                background-color: #FEECECFF;
              }
            }

            &.disabled {
              background-color: #e5e5e5;

              &:hover {
                background-color: '#e5e5e5';
              }
            }

            &:last-child {
              td {
                border-bottom: unset;
              }
            }
          }
        }
      }
    }
  }

  .coupon--send--footer {
    .footer--button--wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1rem;
    }
  }
}