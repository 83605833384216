.best-review-detail {
  max-width: 1080px;

  .field {
    input {
      &.readonly {
        border: none;
        width: 100%;
      }
    }
  }

  .field--reviews {
    display: block;

    .field__body {
      display: block;
      margin-top: 6px;
      padding-left: 6px;
    }

    table {
      margin-top: 6px;

      td {
        padding: 0 6px;
      }
    }

    .field--reviews__selected {
      text-decoration: underline;
    }
  }

  .bestReviewAdd_buttons {
    display: flex;
    margin-top: 12px;
    justify-content: flex-end;

    .btn {
      margin-left: 6px;
    }
  }

  .btn-detail-view {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .table-wrapper {
    thead,
    tbody {
      display: block;
    }

    tbody {
      max-height: 400px;
      overflow-y: auto;
      overflow-x: auto;
      width: 100%;
      //&::-webkit-scrollbar {
      //  display: none;
      //}
    }

    tr {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: #f5f5f5;
      }

      td {
        overflow: auto;
      }

      .review-content {
        height: 100%;
      }

      td,
      th {
        min-width: 70px;
        height: 50px;
        width: 100%;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
          min-width: 600px;
        }

        &:nth-child(1) {
          min-width: 20px;
        }

        &:nth-child(2) {
          min-width: 40px;
        }

        &:nth-child(3) {
          min-width: 60px;
        }

        &:nth-child(5) {
          min-width: 40px;
        }

        &:nth-child(6) {
          min-width: 60px;
        }
      }
    }
  }
}
