.lecture-waiting-list {
  .lecture-waiting-list__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 12px;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .lecture-waiting-list__search {
    margin-left: 12px;
    display: inline-flex;
    vertical-align: top;

    select {
      height: 24px;
    }

    input {
      margin: 0 3px;
      height: 24px;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .lecture-waiting-list__total {
    margin-bottom: 12px;
    display: flex;
    align-items: flex-start;

    .btn {
      margin-left: auto;
    }
  }

  .waiting-list__status {
  }

  .waiting-list__status__types {
    display: flex;
    padding: 6px;
    border-top: 1px solid #999;
  }

  .waiting-list__status__type {
    margin-right: 12px;
    font-weight: bold;
    color: #999;
    cursor: pointer;
  }

  .waiting-list__status__type--selected {
    color: black;
    pointer-events: none;
  }

  .waiting-list__status__statuses {
    padding: 6px;
    border-top: 1px solid #999;
  }

  .waiting-list__status__selected-statuses {
    padding: 6px;
    border-top: 1px solid #999;
  }

  .waiting-list__status__clear {
    display: inline-flex;
    font-size: 12px;
    cursor: pointer;
  }
}
