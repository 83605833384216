.nav {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .header {
    padding: 12px 24px;
    color: white;
    background-color: black;

    .header__title {
      font-size: 21px;
      font-weight: bold;
    }
  }

  .body {
    display: flex;
    flex: 1;
  }

  .content {
    padding: 12px 18px 300px 18px;
    flex: 1;

    .breadcrumb {
      margin-bottom: 18px;
    }
  }
}
