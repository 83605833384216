.menu {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;

  .menu__name {
    font-size: 18px;
    color: #444;
    text-decoration: none;
  }

  .menu__sub-menus {
    margin-top: 6px;
  }
}

.menu--selected {
  .menu__name {
    color: black;
    font-weight: bold;
  }
}
