.reviews {
  .toolbar {
    margin-bottom: 12px;
    display: flex;
  }

  .filters__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 12px;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .filters__search {
    margin-left: 12px;
    display: inline-flex;
    vertical-align: top;

    select {
      height: 24px;
    }

    input {
      margin: 0 3px;
      height: 24px;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .btns {
    .making-btn {
      all: unset;
    }

    margin-left: auto;

    .btn {
      margin-left: 6px;
    }
  }

  .btn-detail-view {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }
}
