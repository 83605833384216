.third-party-list {
  section {
    display: flex;
    align-items: flex-start;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
      margin-bottom: 6px;
    }

    .btn {
      width: 130px;
      white-space: nowrap;
      margin-left: 5px;
    }
  }

  .third-party-list__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 6px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .third-party-list-top-container {
    display: flex;
    justify-content: space-between;
  }

  .third-party-list__total {
    margin-bottom: 6px;
  }

  .third-party-list__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 6px;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .user-class-count {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .waiting-count {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn-download {
    color: #75b96d;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn-download {
    color: #75b96d;
  }
}

.swal2-title {
  font-size: 1.25rem !important;
  text-align: left !important;
  font-weight: 800 !important;
  padding: 0.8em 3em 0 1em !important;
}

.swal2-html-container {
  text-align: left !important;
  font-size: 1rem !important;

  ul,
  ol {
    margin-block-start: 0.25rem !important;
    padding-inline-start: 1rem !important;
  }

  ol {
    li {
      font-weight: 600 !important;

      ul {
        li {
          font-weight: normal !important;
        }
      }

      + li {
        margin-top: 1rem !important;

        ul {
          li {
            font-size: 0.875rem !important;

            + li {
              margin-top: 0.25rem !important;
            }
          }
        }
      }
    }
  }
}
