.posts {
  .MuiList-root {
    font-size: 0.875rem;
  }

  .MuiSelect-select {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    height: 1.5rem;
  }

  .posts__header {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
    }
  }

  .posts__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;

    .MuiFormControl-root {
      margin: 0 0.5rem;

      input {
        width: 5.75rem;
        height: 1.5rem;
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
      }
    }
  }

  .posts__search {
    display: inline-flex;
    align-items: center;
    margin-left: 1.5rem;
    column-gap: 0.5rem;

    input {
      width: 12rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }
  }

  .posts-top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .posts__total {
    margin-bottom: 6px;
    background-color: #ffffff;
    padding: 0 0 6px 0;
  }

  .top-posts-top-container {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
  }

  .top-posts-container {
    margin-bottom: 3rem;
  }

  .posts__info {
    color: #8d8d8d;
    font-weight: bold;
  }

  .not-dragging tbody tr {
    background-color: transparent;
    transition: background-color 0.5s ease;
  }

  table {
    tr {
      height: 60px;
      width: 100%;
      &:hover {
        background-color: #eee;
      }
    }

    td:nth-child(1) {
      width: 3%;
    }
    td:nth-child(2) {
      width: 5%;
    }
    td:nth-child(3) {
      width: 25%;
    }
    td:nth-child(4) {
      width: 5%;
    }
    td:nth-child(5) {
      width: 8%;
    }
    td:nth-child(6) {
      width: 10%;
    }
    td:nth-child(7) {
      width: 9%;
    }
    td:nth-child(8) {
      width: 5%;
    }
    td:nth-child(9) {
      width: 10%;
    }
    td:nth-child(10) {
      width: 5%;
    }
  }

  .posts-blue tbody tr {
    background-color: #f3f5ff;
    transition: background-color 0.5s ease;
  }

  .posts-red tbody tr {
    background-color: #ffe7e7;
    transition: background-color 0.5s ease;
  }

  .dragged-row {
    background-color: #f5f5f5 !important;
    opacity: 0.5 !important;
  }
}