.chip {
  display: inline-flex;
  margin-right: 3px;
  padding: 3px 9px;
  color: white;
  font-size: 12px;
  background-color: #999;
  box-shadow: 1px 1px 2px #ccc;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  .chip__delete {
    margin-left: 6px;
  }
}

.chip--selected {
  background-color: #dc5464;
}

.chip--disabled {
  cursor: default;
  opacity: 0.6;
}
