.banner-new {
  max-width: 780px;

  .banner-new__fields {
    padding: 18px;
  }

  .select-search input {
    width: 300px;
    box-sizing: border-box;
    border: 1px solid #999;
    border-radius: 6px;
  }

  .select-search__select {
    border: 1px solid #999;
  }

  .banner-new__buttons {
    margin-bottom: 18px;
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 6px;
    }
  }
}
