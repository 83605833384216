.artists {
  section {
    display: flex;
    justify-content: space-between;

    a {
      margin-left: auto;
    }

    .artists-action-buttons-wrapper {
      justify-content: end;

      button {
        + button {
          margin-left: 1rem;
        }
      }
    }

    .artists__search {
      display: flex;
      align-items: center;

      input,
      select {
        height: 24px;
        box-sizing: border-box;
      }

      input {
        margin: 0 6px;
      }

      button {
        width: 60px;
        height: 24px;
        font-size: 12px;
        border-radius: 3px;
        box-shadow: none;
      }
    }
  }

  &__total {
    margin-bottom: 6px;
  }

  .user-class-count {
    text-decoration: underline #777;
  }
}

.swal2 {
  &-title {
    font-size: 1.25rem !important;
    text-align: left !important;
    font-weight: 800 !important;
    padding: 0.8em 3em 0 1em !important;
  }

  &-html-container {
    text-align: left !important;
    font-size: 1rem !important;

    ul,
    ol {
      margin-block-start: 0.25rem !important;
      padding-inline-start: 1rem !important;
    }

    ol {
      li {
        font-weight: 600 !important;

        ul {
          li {
            font-weight: normal !important;
          }
        }

        + li {
          margin-top: 1rem !important;

          ul {
            li {
              font-size: 0.875rem !important;

              + li {
                margin-top: 0.25rem !important;
              }
            }
          }
        }
      }
    }
  }

  &-label {
    display: inline-block;
    width: 120px;
  }
}

.form-thumbnail {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.thumbnail-preview {
  width: 65%;

  img {
    max-width: 80%;
  }
}

.list-thumbnail {
  width: 200px;
}
