.lecture--likes {
  .MuiList-root {
    font-size: 0.875rem;
  }

  .MuiSelect-select {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .lecture--likes--filters {
    display: flex;
    flex-direction: column;
    row-gap: 0.875rem;
    font-size: 0.875rem;
    margin: 1rem 0 0.5rem 0;
    color: #777777;

    input {
      padding: 0.25rem 0.5rem;
      border-radius: 0.375rem;
      min-height: 1.25rem;

      &:focus {
        outline: none;
        border-color: #c6cacd;
      }
    }

    .filters--date--wrap {
      display: flex;
      align-items: center;

      input {
        width: 5.75rem;
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
      }
    }

    .filters--bottom {
      display: flex;
      justify-content: space-between;

      .filters--search--wrap {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;

        input {
          width: 12rem;
          padding: 0.25rem 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }

  .lecture--likes--data {
    .lecture--likes--data--top {
      padding: 0 0 0.475rem 0;
      font-size: 0.875rem;
    }

    .lecture--likes--data--bottom {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .table--wrap {
      max-height: 600px;
      overflow: auto;
      border-radius: 0.5rem;
      box-shadow: 0 0 0 1px #DDDDDDFF;
      margin-bottom: 0.5rem;
    }

    .MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;

      .MuiTableContainer-root {
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 0.5rem;
        box-shadow: 0 0 0 1px #DDDDDDFF;

        table {
          border: unset;
          margin: 0;

          th {
            background-color: rgb(246, 246, 246);
          }

          td, th {
            border-bottom: 1px solid #DDDDDDFF;
            border-left: 1px solid #EEEEEE;

            &:first-child {
              border-left: none;
            }
          }

          tr {
            transition: background-color 0.25s ease;

            &:last-child {
              td {
                border-bottom: unset;
              }
            }
          }

          td {
            padding: 8px;

            &:first-child {
              padding: 0 0 0 4px;
            }
          }
        }
      }
    }
  }
}