.coupon__issues {
  .making-btn {
    all: unset;
    float: right;
  }

  .coupon__search {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 3px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .coupon__total {
    margin-bottom: 12px;
  }
}
