.sub-menu {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;

  .sub-menu__name {
    color: #444;
    text-decoration: none;
  }
}

.sub-menu--selected {
  .sub-menu__name {
    color: black;
    font-weight: bold;
  }
}
