.survey {
  max-width: 1440px;
  @media screen and (max-width: 1090px) {
    max-width: 1080px;
  }

  .field__title {
    width: 180px;
  }

  .survey__buttons {
    display: flex;
    margin-top: 12px;
    justify-content: flex-end;

    .btn {
      margin-left: 6px;
    }
  }

  tbody tr {
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
}