.third-party-list {
  .third-party-list__search {
    display: inline-flex;
    vertical-align: top;
    margin-bottom: 18px;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 6px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .third-party-list__total {
    margin-bottom: 12px;
    display: flex;
    align-items: flex-start;

    .btn {
      margin-left: auto;
    }
  }

  tr {
    td:nth-child(2) {
      min-width: 50px;
    }

    td:nth-child(5) {
      max-width: 80px;
      overflow: auto;
    }

    td:nth-child(5)::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 3px;
      height: 5px;
      background-color: rgba(115, 115, 115, 0.14);
    }

    td:nth-child(5)::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #93b1dc;
    }

    td:nth-child(13) {
      input {
        max-width: 80px;
        text-align: center;
      }

      max-width: 80px;
    }

    td:last-child {
      a {
        display: block;
        margin-right: 0px;
        margin-bottom: 3px;
      }

      .field__body {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.swal2-title {
  font-size: 1.25rem !important;
  text-align: left !important;
  font-weight: 800 !important;
  padding: 0.8em 3em 0 1em !important;
}

.swal2-html-container {
  text-align: left !important;
  font-size: 1rem !important;

  ul,
  ol {
    margin-block-start: 0.25rem !important;
    padding-inline-start: 1rem !important;
  }

  ol {
    li {
      font-weight: 600 !important;

      ul {
        li {
          font-weight: normal !important;
        }
      }

      + li {
        margin-top: 1rem !important;

        ul {
          li {
            font-size: 0.875rem !important;

            + li {
              margin-top: 0.25rem !important;
            }
          }
        }
      }
    }
  }
}
