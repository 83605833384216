.notices {
  section:first-child {
    display: flex;
    align-items: center;

    a {
      margin-left: auto;
    }

    .making-btn {
      all: unset;
      margin-left: auto;
    }
  }

  .notices__date {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 10px;

    input {
      margin: 0 3px;
      width: 90px;
      height: 24px;
      box-sizing: border-box;
    }
  }

  .notices__search {
    display: flex;
    align-items: center;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 3px;
      height: 24px;
      box-sizing: border-box;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .notices-top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .notices__total {
    margin-bottom: 6px;
  }

  .btn-detail {
    padding: 0 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .notices__total {
    background-color: #ffffff;
    margin: unset ;
    padding: 0 0 6px 0;
  }

  .top-notices-top-container {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
  }

  .top-notices-container {
    margin-bottom: 3rem;
  }

  .notices__info {
    color: #8d8d8d;
    font-weight: bold;
  }

  .not-dragging tbody tr {
    background-color: transparent;
    transition: background-color 0.5s ease;
  }

  table {
    tr {
      height: 60px;
      width: 100%;
      &:hover {
        background-color: #eee;
      }
    }

    td:nth-child(1) {
      width: 3%;
    }
    td:nth-child(2) {
      width: 5%;
    }
    td:nth-child(3) {
      width: 25%;
    }
    td:nth-child(4) {
      width: 5%;
    }
    td:nth-child(5) {
      width: 8%;
    }
    td:nth-child(6) {
      width: 10%;
    }
    td:nth-child(7) {
      width: 9%;
    }
    td:nth-child(8) {
      width: 5%;
    }
    td:nth-child(9) {
      width: 10%;
    }
    td:nth-child(10) {
      width: 5%;
    }
  }

  .notices-blue tbody tr {
    background-color: #f3f5ff;
    transition: background-color 0.5s ease;
  }

  .notices-red tbody tr {
    background-color: #ffe7e7;
    transition: background-color 0.5s ease;
  }

  .dragged-row {
    background-color: #f5f5f5 !important;
    opacity: 0.5 !important;
  }
}