.companies__container {
  .MuiList-root {
    font-size: 0.875rem;
  }

  .MuiSelect-select {
    min-width: 3rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .companies-filters {
    display: flex;
    row-gap: 0.875rem;
    font-size: 0.875rem;
    margin: 1rem 0 0.5rem 0;
    color: #777777;
    align-items: center;
    justify-content: space-between;

    .search-bar {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
    }

    input {
      padding: 0.25rem 0.5rem;
      border-radius: 0.375rem;
      min-height: 1.25rem;

      &:focus {
        outline: none;
        border-color: #c6cacd;
      }
    }
  }
  .companies-data__container {
    .companies-data__header {
      padding: 0 0 0.475rem 0;
      font-size: 0.875rem;
      color: #333;
    }

    .table--wrap {
      max-height: 600px;
      overflow: auto;
      border-radius: 0.5rem;
      box-shadow: 0 0 0 1px #ddddddff;
      margin-bottom: 0.5rem;
    }

    .MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;

      .MuiTableContainer-root {
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 0.5rem;
        box-shadow: 0 0 0 1px #ddddddff;

        table {
          border: unset;
          margin: 0;

          th {
            background-color: rgb(246, 246, 246);
          }

          td,
          th {
            cursor: default;
            border-bottom: 1px solid #ddddddff;
            border-left: 1px solid #eeeeee;

            &:first-child {
              border-left: none;
            }
          }

          tr {
            transition: background-color 0.25s ease;

            &:last-child {
              td {
                border-bottom: unset;
              }
            }
          }

          td {
            padding: 8px;

            &:first-child {
              padding: 0 0 0 4px;
            }
          }
        }
      }
    }
  }

  .other-companies__container {
    margin-top: 1rem;

    .other-companies-data__header {
      padding: 0 0 0.475rem 0;
      font-size: 0.875rem;
    }
  }
}
