.coupons {
  .coupons__tabs {
    display: flex;
    margin-bottom: 12px;
  }

  .coupons__tab {
    padding: 0 12px 6px 12px;
    border-bottom: 1px solid black;
    cursor: pointer;
  }

  .coupons__tab--selected {
    font-weight: bold;
    color: #dc5464;
    border-width: 3px;
    border-color: #dc5464;
    cursor: default;
  }

  .coupons__content {
  }
}
