div.content {
  padding-bottom: 5rem !important;
  width: 100% !important;
  overflow: hidden !important;
}

div.container {
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 1rem;
  transition: opacity 0.2s;
  opacity: 1;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;

  &.blind {
    opacity: 0;
  }

  .lecture-info-wrap,
  .student-list-wrap {
    display: flex;
    gap: 20px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  .lecture-info-wrap {
    .lecture-thumbnail {
      width: min(40%, 500px);
      border-radius: 0.5rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 0.5rem;
        overflow: hidden;
        display: block;
      }
    }

    .lecture-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      padding: 0.5rem 0;

      &-item {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: #333;
        height: 1.5rem;
        line-height: 1.5rem;

        &_title {
          font-weight: 600;
          line-height: 1.5rem;
          width: 80px;
        }
      }
    }
  }

  .student-list-wrap {
    flex-direction: column;

    ul.student-list {
      display: block;
      list-style: none;
      position: relative;
      width: 100%;
      padding: 0;
      margin: 0;
      overflow-x: auto;

      a {
        text-decoration: none;
        color: #333;
      }

      .take-attendance-btn {
        background-color: transparent;
        cursor: pointer;
        outline: none;
        padding: 1rem 2rem 0.875rem 2rem;
        width: fit-content;
        font-size: inherit;
        border-radius: 0.5rem;
        border: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        box-shadow: 0 2px 3px -1px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

        &.disabled:hover {
          background-color: #f5f5f5;
        }

        &.disabled {
          cursor: not-allowed;
          box-shadow: none;
        }
      }

      li {
        margin-bottom: 1rem;
        height: 5rem;
        display: flex;
        align-items: center;
        width: fit-content;
        position: relative;

        &.student-list-header {
          background-color: #f5f5f5;
          border: 1px solid #e5e5e5;
          border-radius: 0.5rem;
          box-shadow: 0 2px 3px -1px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
          font-weight: 600;
          text-align: center;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        &.student-row {
          border: 1px solid #e5e5e5;
          border-radius: 0.5rem;
          background-color: #fff;
          box-shadow: 0 2px 3px -1px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
        }

        .student-info {
          min-width: 600px;
          display: flex;
          gap: 0.5rem;
          justify-content: space-between;
          height: 100%;
          position: sticky;
          left: 0;
          top: 0;
          background-color: inherit;
          padding-right: 1rem;

          .col:nth-child(2) {
            width: 250px;
          }

          .ico-review-written {
            aspectratio: 1/1;
            height: 1.25rem;
            margin: 0;
            padding: 0;

            @media screen and (max-width: 768px) {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .schedule-list {
          width: 70%;
          display: flex;
          justify-content: flex-start;
          height: 100%;
          gap: 1rem;
        }

        .col {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 150px;
          height: 100%;
          text-align: center;

          span {
            color: #777;
          }
        }
      }
    }
  }
}

// width 1200 이하
@media screen and (max-width: 1200px) {
  div.container {
    font-size: 0.875rem;

    .student-list-wrap {
      ul.student-list {
        .take-attendance-btn {
          padding: 0.5rem 1rem 0.375rem 1rem;
        }

        li {
          height: 4rem;

          .student-info {
            min-width: 400px;
            width: 450px;

            .col {
              width: 120px;
              min-width: 120px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .col:first-child {
              width: 80px;
              min-width: 80px;
            }
          }

          &.student-row {
            .student-info {
              .col {
                text-align: left;
                justify-content: flex-start;
                padding-left: 1rem;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
                vertical-align: middle;
                height: 4rem;
                line-height: 4rem;
              }
            }
          }
        }
      }
    }
  }
}

// width 960 이하
@media screen and (max-width: 960px) {
  div.container {
    max-width: 100%;

    .lecture-info-wrap {
      .lecture-thumbnail {
      }
    }

    .student-list-wrap {
      ul.student-list {
        li {
          .col {
            min-width: 120px;
          }

          .take-attendance-btn {
            white-space: nowrap;
          }

          &.student-list-header {
            display: none;
          }

          &.student-row {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 8rem;
            padding: 0;

            .student-info {
              top: auto;
              width: 80vw;
              justify-content: flex-start;

              .col:nth-child(1) {
                font-weight: 600;
                font-size: 1rem;
                width: 120px;
              }
            }
          }
        }
      }
    }
  }
}

// width 768 이하
@media screen and (max-width: 768px) {
  div.container {
    padding: 0;

    .lecture-info-wrap {
      flex-direction: column;

      .lecture-thumbnail {
        width: 100%;
        height: min(20rem, 50vw);

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .lecture-info-item {
        flex-wrap: nowrap;
        height: auto;

        > * {
          width: 100%;

          &:first-child {
            width: 5rem;

            @media screen and (max-width: 768px) {
              min-width: 4rem;
            }
          }
        }
      }
    }

    .student-list-wrap {
      ul.student-list {
        li {
          &.student-row {
            padding: 1rem 0rem 0;
          }

          .student-info {
            width: 70vw !important;
            max-width: 70vw !important;
            min-width: 70vw !important;
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            text-align: center;
            gap: 0 !important;

            .col {
              width: 100% !important;
              height: 1rem !important;
              line-height: 1rem !important;
              text-align: center !important;
            }

            .col-2 {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
