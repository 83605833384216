.buttons {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  button {
    margin: 0 0.5rem;
  }
}

.swal2-validation-message {
  color: #333;
  background: #f2f2f2;
  display: flex;
}

.hidden {
  display: none !important;
}

.mb-3 {
  margin-bottom: 12px;
}

.mt-10 {
  margin-top: 40px;
}
