.coupon__usages {
  .coupon__search {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;

    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    input {
      margin: 0 3px;
    }

    button {
      width: 60px;
      height: 24px;
      font-size: 12px;
      border-radius: 3px;
      box-shadow: none;
    }
  }

  .coupon__excel--btn {
    width: 120px;
    height: 36px;
    color: white;
    box-shadow: 1px 1px 2px #ccc;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    background-color: #5dbb63;
    float: right;
    margin-bottom: 12px;

    &:hover {
      opacity: 0.6;
    }

    &.coupon__excel__disabled--btn {
      opacity: 0.3;
    }
  }
}
